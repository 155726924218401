<template>
  <div class="animated fadeIn">
    <h4 class="mb-3">
      Редактирование списка перевозчиков <span v-if="form.name">"{{ form.name }}"</span>
    </h4>
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <b-form-group
        id="usernameInputGroup1"
        label="Название списка"
        label-for="name"
      >
        <b-form-input
          id="name"
          v-model.trim="$v.form.name.$model"
          type="text"
          name="name"
          :state="chkState('name')"
          aria-describedby="input1LiveFeedback1"
          placeholder="Название списка"
          autocomplete="name"
          autofocus
        />
        <b-form-invalid-feedback
          id="input1LiveFeedback1"
        >
          Это поле обязательно для заполнения
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="form-group-el-select"
        label="Перевозчик"
      >
        <b-form-row>
          <b-col>
            <el-select
              v-model="form.contractor.value"
              class="form-control"
              name="contractor"
              placeholder="Начните вводить название компании перевозчика"
              :filterable="true"
              :remote="true"
              reserve-keyword
              :remote-method="contractorRemote"
              :loading="loading"
              @change="onContractorChange(form.contractor.value)"
            >
              <el-option
                v-for="item in form.contractor.options"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              />
            </el-select>
          </b-col>
          <b-col cols="auto">
            <b-button
              type="button"
              variant="secondary"
              class="mr-2"
              :class="{'loading' : loading}"
              :disabled="loading"
              @click="addAllContractors()"
            >
              Добавить всех
            </b-button>
          </b-col>
        </b-form-row>
      </b-form-group>
      <b-card
        v-if="contractors.items.length > 0"
        no-body
      >
        <b-card-header>Перевозчики</b-card-header>
        <b-card-body>
          <v-client-table
            id="dataTable"
            ref="table"
            :data="contractors.items"
            :columns="contractors.columns"
            :options="contractors.options"
            :theme="theme"
          >
            <span
              slot="phone"
              slot-scope="props"
            >
              <a
                :href="`tel:${props.row.phone}`"
                class="icon-email"
              >{{ props.row.phone }}</a>
            </span>
            <span
              slot="driversCount"
              slot-scope="props"
            >
              <span v-if="!props.row.driversCount">0</span>
              <span v-else>{{ props.row.driversCount }}</span>
            </span>
            <span
              slot="trucksCount"
              slot-scope="props"
            >
              <span v-if="!props.row.trucksCount">0</span>
              <span v-else>{{ props.row.trucksCount }}</span>
            </span>
            <span
              slot="trailersCount"
              slot-scope="props"
            >
              <span v-if="!props.row.trailersCount">0</span>
              <span v-else>{{ props.row.trailersCount }}</span>
            </span>
            <span
              slot="notificationEmail"
              slot-scope="props"
            >
              <a
                v-if="props.row.notificationEmail"
                :href="`mailto:${props.row.notificationEmail}`"
                class="icon-email"
              >{{ props.row.notificationEmail }}</a>
              <a
                v-else
                :href="`mailto:${props.row.email}`"
                class="icon-email"
              >{{ props.row.email }}</a>
            </span>
            <span
              slot="actions"
              slot-scope="props"
            >
              <a
                v-if="$store.getters.isAdmin"
                v-b-tooltip.hover.bottom="'Удалить'"
                class="fa fa-close fa-lg text-danger table-action-button"
                @click="deleteChosenContractor(props.row.id)"
              />
            </span>
          </v-client-table>
        </b-card-body>
      </b-card>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="$v.form.$invalid || loading"
          :class="{'loading' : loading}"
        >
          Сохранить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import Vue from 'vue';
import {Event, ClientTable} from 'vue-tables-2';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import notifications from '../../../components/mixins/notifications';
import {
  customersContractorsList,
  customersContractorsListRead,
  customersContractorRead,
  customersContractorsListUpdate,
  customersAllContractorsList,
} from '../../../services/api';

const formShape = {
  name: '',
};

Vue.use(ClientTable);

export default {
  name: 'ContractorListsListEdit',
  components: {
    ClientTable,
    Event,
  },
  mixins: [validationMixin, notifications],
  props: ['contractorListId'],
  data: function() {
    return {
      form: {
        name: null,
        contractor: {
          options: [],
          value: null,
        },
      },
      contractors: {
        columns: [
          'name',
          'phone',
          'driversCount',
          'trucksCount',
          'trailersCount',
          'notificationEmail',
          'INN',
          'actions',
        ],
        options: {
          headings: {
            'name': 'Название',
            'phone': 'Телефон',
            'driversCount': 'Кол-во водителей',
            'trucksCount': 'Кол-во грузовиков',
            'trailersCount': 'Кол-во прицепов',
            'notificationEmail': 'Email',
            'INN': 'ИНН',
            'actions': '',
          },
          sortable: ['name'],
          filterable: ['name'],
          sortIcon: {
            base: 'fa',
            up: 'fa-sort-asc',
            down: 'fa-sort-desc',
            is: 'fa-sort',
          },
          orderBy: {
            column: 'name',
          },
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'fixed',
          },
          texts: {
            count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
            first: 'Первая',
            last: 'Последняя',
            filter: 'Фильтр:',
            filterPlaceholder: 'Поисковый запрос',
            limit: 'Записи:',
            page: 'Страница:',
            noResults: 'Нет совпадающих записей',
            filterBy: 'Фильтр по {column}',
            loading: 'Загрузка...',
            defaultOption: 'Выбор {column}',
            columns: 'Столбцы',
          },
        },
        items: [],
        fullDistance: '',
        theme: 'bootstrap4',
      },
      loading: false,
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  computed: {
    formStr() {
      const calculationFormData = {};
      calculationFormData.name = this.form.name;
      calculationFormData.customer = {id: this.$store.state.user.ownerId};
      calculationFormData.contractors = this.contractors.items.map((item) => {
        return {id: item.id};
      });
      return calculationFormData;
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
      contractor: {
        value: '',
      },
    },
  },
  mounted() {
    this.customersContractorsListRead();
    this.contractorRemote('');
  },
  methods: {
    async customersContractorsListRead() {
      this.loading = true;
      const response = await customersContractorsListRead(this.$store.state.user.ownerId, this.contractorListId);
      if (response && response.status === 200) {
        this.form.name = response.data.name;
        this.contractors.items = response.data.contractors;
      }
      this.loading = false;
    },
    async addAllContractors() {
      this.loading = true;
      const response = await customersAllContractorsList(this.$store.state.user.ownerId, {valid: true});
      if (response && response.status === 200) {
        this.contractors.items = response.data.items.map((customerContractor) => {
          return customerContractor.contractor;
        });
        this.form.contractor.options = [];
      }
      this.loading = false;
    },
    async contractorRemote(query) {
      this.loading = true;
      const response = await customersContractorsList(this.$store.state.user.ownerId, {
        name: query,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.contractors.items.forEach((item) => {
          response.data.items = response.data.items.filter((x) => x.contractor.id !== item.id);
        });
        this.form.contractor.options = response.data.items.map((item) => {
          return {
            value: item.contractor.id,
            text: item.contractor.name,
          };
        });
      }
      this.loading = false;
    },
    async onContractorChange(contractorId) {
      this.loading = true;
      const response = await customersContractorRead(this.$store.state.user.ownerId, contractorId);
      if (response && response.status === 200) {
        this.form.contractor.options = this.form.contractor.options.filter((item) => item.value !== contractorId);
        this.form.contractor.value = null;
        this.contractors.items.unshift(response.data.contractor);
      }
      this.loading = false;
    },
    deleteChosenContractor(contractorId) {
      this.contractors.items = this.contractors.items.filter((item) => item.id !== contractorId);
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        const response = await customersContractorsListUpdate(
          this.$store.state.user.ownerId,
          this.contractorListId, this.formStr,
        );
        if (response && response.status === 200) {
          this.showSuccess('Список обновлен');
          this.$router.push({path: '/contractor-lists-list'});
        }
        this.loading = false;
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else if (field.$invalid) {
        return false;
      } else {
        return true;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
  },
};
</script>

<style lang="scss">
</style>
